import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "bootstrap";
import "./assets/css/scss/app.scss";
import UAParser from "ua-parser-js";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Vue.mixin({
  data() {
    return {
      isPotrait: false,
      isUADesktop: false,
      isUAMobile: false,
      isUATablet: false,
    };
  },
  methods: {
    globalOrientationCheck() {
      var mql = window.matchMedia("(orientation: portrait)");

      if (mql.matches) {
        this.isPotrait = true;
      } else {
        this.isPotrait = false;
      }

      mql.addListener(function (m) {
        if (m.matches) {
          this.isPotrait = true;
        } else {
          this.isPotrait = false;
        }
      });
    },
    globalDeviceCheck: function () {
      let parser = new UAParser();
      let parser_result = parser.getResult();

      if (parser_result.device.type == "mobile") {
        this.isUAMobile = true;
      } else if (parser_result.device.type == "tablet") {
        this.isUATablet = true;
      } else {
        this.isUADesktop = true;
      }
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
